import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Form,
  Select,
  InputNumber,
  Spin,
  Button,
  Typography,
  message,
} from "antd";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { QUERIES } from "../../api/queries";
import Axios from "axios";
import { useI18nContext, getLabel } from "../../api/i18nService";
import { useStateValue } from "../stateProvider/stateProvider";
import "./quotationNew.css";
import DOMPurify from "dompurify"; //DOMPurify.sanitize()
import { getServiceUrl } from "../../util/util";

const { Option } = Select;
const { Text } = Typography;

/**
 * quotationNew.js
 * Generates and displays the form to create a new Quotation.
 */

const QuotationNew = (props) => {
  const { strings } = useI18nContext();
  const [
    {
      userDetails,
      dealerIndex,
      token,
      currentDealer,
      userDealers,
      tempQuotation,
      tempQuotationFields,
    },
    dispatch,
  ] = useStateValue();
  const [fields, setFields] = useState(null);
  const [quotationFields, setQuotationFields] = useState(null);
  const [serviceUrl, setServiceUrl] = useState(null);
  const [totalValue, setTotalValue] = useState(0);
  const [alreadyAddedToTotal, setAlreadyAddedToTotal] = useState([]);
  const testDealers = ["B2Bike Stephanie", "Cogetix Service"];
  const [calculationSheetReady, setCalculationSheetReady] = useState(false);
  const [hasParentCompany, setHasParentCompany] = useState(false);

  // Define default quotation in component state (i.e. model)
  const [quotation, setQuotation] = useState({
    dealer_fk: currentDealer.id,
    employee_fk: props.employeeId,
    deliverability: "",
    quotation_source: "DealerAppV2",
    quotation_date: new Date().toISOString(),
    active: true,
    bike_type_fk: null,
    bike_brand_fk: null,
    test: false,
  });
  const [calculationSheetId, setCalculationSheetId] = useState(null);

  // Query company_fields (for company id provided via props) and bike_brands
  const { error, loading, data } = useQuery(QUERIES.COMPANY_FIELDS_AND_BIKES, {
    variables: { id: props.companyId },
    fetchPolicy: "no-cache",
    onError: (err) => {
      console.error(err);
    },
  });

  // Wait for the service URL to be fetched and set
  useEffect(() => {
    getServiceUrl("quotationCreateCalculationSheet", token).then((url) => {
      setServiceUrl(url);
    });
  }, [token]);

  useEffect(() => {
    const valueFields = ["BIKE_VALUE", "LOOSE_PARTS_VALUE", "CLOTHING"];

    const fieldsToAdd = (props?.fields ?? []).filter((field) =>
      valueFields.includes(field?.field?.fieldType?.code)
    );
    const valuesToAdd = fieldsToAdd.map((field) => Number(field?.value ?? 0));
    const totalResult = valuesToAdd.reduce(
      (acc, currentValue) => acc + currentValue,
      0
    );

    setTotalValue(totalResult);
    setAlreadyAddedToTotal((old) => [...old, ...fieldsToAdd]);
  }, []);

  useEffect(() => {
    if (data && serviceUrl) {
      /**
       * If and when we have the company_fields and bike brands, we already prepare a calculation_sheet
       * This is a copy of a company template in Google Drive.
       * The Id of said calculation_sheet is returned by below request and subsequently stored in component state.
       */
      const res = data;
      console.log(serviceUrl);
      // Make Axios POST request to fetch the calculation sheet ID

      if (
        res.company.calculation_sheet === null &&
        res.company.company_parent_fk !== null
      ) {
        setHasParentCompany(true);
        setCalculationSheetReady(true);
      }

      if (res.company.calculation_sheet !== null) {
        Axios.post(
          serviceUrl,
          {
            companyCalculationSheet: res.company.calculation_sheet,
            companyFolderId: res.company.folder_id,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
          .then((sheetRes) => {
            setCalculationSheetId(sheetRes.data.sheet); // Set the calculationSheetId here
            setCalculationSheetReady(true);
          })
          .catch((err) => {
            console.error("QuotationNew - could not get sheet copy: ", err);
          });
      }

      // Meanwhile, we prepare the quotation fields by first sorting the by their index property.
      setFields(res.company.fields.sort((a, b) => a.index - b.index));

      /**
       *  ... and check there is no tempQuotation for the same employee in the global state.
       * Temp quotation (& its fields) are stored in global state temporarily when a user needs to exit this
       * component to fill in additional info (e.g. missing speed pedelec info)
       */
      if (tempQuotationFields && tempQuotation) {
        if (tempQuotation.employee_fk === props.employeeId) {
          setQuotationFields(tempQuotationFields);
          dispatch({
            type: "setTempQuotationFields",
            tempQuotationFields: null,
          });
          setQuotation(tempQuotation);
          dispatch({ type: "setTempQuotation", tempQuotation: null });
        } else {
          createQuotationFields(res.company.fields);
        }
      }
      // If they cannot be found in global state (normal circumstance), quotatioFields are generated
      else {
        createQuotationFields(res.company.fields);
      }
      if (!!props.bikeDetails && !tempQuotation && !tempQuotationFields) {
        setQuotation((old) => {
          return {
            ...old,
            deliverability: props.bikeDetails.deliverability,
            bike_type_fk: props.bikeDetails.bike_type_fk,
            bike_brand_fk: props.bikeDetails.bike_brand_fk,
          };
        });
      }
    }
  }, [data, serviceUrl]);

  const [createQuotation, { loading: mutationLoading }] = useMutation(
    QUERIES.CREATE_QUOTATION
  );

  const checkIfTestQuotation = (dealerId) => {
    const dealer = userDealers.find((el) => {
      return el.id == dealerId;
    });
    if (!dealer) return false;

    return (
      testDealers.includes(dealer.name) ||
      dealer.name.toLowerCase().includes("b2bike")
    );
  };

  const canSave = () => {
    return (
      !!quotation.bike_brand_fk &&
      !!quotation.bike_type_fk &&
      !!quotation.deliverability &&
      quotation.dealer_fk !== -1 &&
      calculationSheetReady
    );
  };

  const onSave = async () => {
    // Check ALD fields
    if (!checkAldFields()) {
      return message.error(
        <span>
          {`${getLabel("ALD fields", true, strings)} ${getLabel(
            "missing",
            false,
            strings
          )}`}
          .{" "}
          <Button
            type="link"
            onClick={() => {
              dispatch({ type: "setTempQuotation", tempQuotation: quotation });
              dispatch({
                type: "setTempQuotationFields",
                tempQuotationFields: quotationFields,
              });
              props.history.replace(
                `/employee/${props.employeeId}/${props.companyId}`
              );
              message.destroy();
            }}
          >
            {getLabel("complete", true, strings)}
          </Button>
        </span>
      );
    }

    // Check Speed Pedelec
    if (quotation.bike_type_fk === "1") {
      if (!canHaveSpeedPedelec()) {
        return message.error(
          <span>
            {`${getLabel(
              "mandatory fields speed pedelec",
              true,
              strings
            )} ${getLabel("missing", false, strings)}`}
            .{" "}
            <Button
              type="link"
              onClick={() => {
                dispatch({
                  type: "setTempQuotation",
                  tempQuotation: quotation,
                });
                dispatch({
                  type: "setTempQuotationFields",
                  tempQuotationFields: quotationFields,
                });
                props.history.replace(
                  `/employee/${props.employeeId}/${props.companyId}`
                );
                message.destroy();
              }}
            >
              {getLabel("complete", true, strings)}
            </Button>
          </span>
        );
      }
    }

    // Prepare fields for mutation
    const fieldsForMutation = [...quotationFields];
    fieldsForMutation.forEach((item) => {
      item.value += "";
      delete item.field;
    });

    try {
      // Make sure the calculationSheetId is available before calling createQuotation
      const createQuotationRes = await createQuotation({
        variables: {
          quotation: {
            ...quotation,
            calculation_sheet: calculationSheetId,
            test: checkIfTestQuotation(quotation.dealer_fk),
          },
          quotationFields: fieldsForMutation,
        },
      });

      if (createQuotationRes.error) {
        throw new Error(createQuotationRes.error);
      } else if (createQuotationRes.data) {
        props.onSave(createQuotationRes);
      }
    } catch (err) {
      console.error("QuotationNew - mutationError: ", err);
      return message.error("Er ging iets mis");
    }
  };

  const createQuotationFields = (fieldArray) => {
    let quoteFields = [];
    let usePropFields = true;

    if (props.fields) {
      fieldArray.forEach((item) => {
        if (!props.fields.find((el) => el.field_fk === item.id)) {
          usePropFields = false;
        }
      });
    }
    if (!usePropFields) {
      message.warning("Kan niet dupliceren.");
      setTotalValue(0);
    }
    if (!props.fields || !usePropFields) {
      fieldArray.forEach((item) => {
        quoteFields.push({
          field_fk: item.id,
          value: item.fieldValues.length > 0 ? item.fieldValues[0].value : "",
        });
      });
    } else {
      quoteFields = props.fields.map((f) => {
        delete f.id;
        delete f.__typename;
        return f;
      });
    }
    setQuotationFields(quoteFields);
  };

  let content = null;

  // Util function - checks whether all info for speed pedelec is present - returns boolean
  const canHaveSpeedPedelec = () => {
    if (
      props.speedPedelecInfo.street == null ||
      props.speedPedelecInfo.street === "" ||
      props.speedPedelecInfo.zip_code == null ||
      props.speedPedelecInfo.zip_code === "" ||
      props.speedPedelecInfo.city == null ||
      props.speedPedelecInfo.city === "" ||
      props.speedPedelecInfo.drivers_license_date == null ||
      props.speedPedelecInfo.drivers_license_date === "" ||
      props.speedPedelecInfo.national_register == null ||
      props.speedPedelecInfo.national_register === ""
    ) {
      return false;
    }
    return true;
  };

  // Util function - checks whether all info for invoice target ALD is present - returns boolean
  const checkAldFields = () => {
    if (props.invoiceTargetInfo) {
      if (props.invoiceTargetInfo.invoice_target.name === "Axus") {
        if (
          !props.invoiceTargetInfo.street ||
          !props.invoiceTargetInfo.zip_code ||
          !props.invoiceTargetInfo.date_of_birth ||
          !props.invoiceTargetInfo.city
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const findQuotationField = (id) =>
    quotationFields.find((el) => {
      return el.field_fk === id;
    });

  /**
   * Generates a UI component for a companyfield based on its fieldType's dataType property.
   * (If this doesn't make sense, have a look at field, quotation_field, field_type etc in the db)
   */
  const mapField = (field) => {
    switch (field.fieldType.dataType.code) {
      case "TEXT":
        return (
          <Input
            defaultValue={
              findQuotationField(field.id)
                ? findQuotationField(field.id).value
                : ""
            }
            onChange={(e) => {
              const val = DOMPurify.sanitize(e.target.value);
              setQuotationFields((old) => {
                const oldField = findQuotationField(field.id);
                old[old.indexOf(oldField)] = { ...oldField, value: val };
                return old;
              });
            }}
            placeholder={
              !!field.description
                ? getLabel(
                    field.description.toLowerCase().replace(/\./g, ""),
                    true,
                    strings
                  )
                : getLabel(
                    field.fieldType.name.toLowerCase().replace(/\./g, ""),
                    true,
                    strings
                  )
            }
          />
        );
      case "NUMBER": {
        // Heb deze toegevoegd aan zowel de onBlur als de onPressEnter props -> zo heb je de keuze
        const handleTotalValue = () => {
          const quotationField = findQuotationField(field.id);
          setAlreadyAddedToTotal([...alreadyAddedToTotal, quotationField]);
          const amountToBeDeducted =
            alreadyAddedToTotal.find(
              (field) => field.field_fk === quotationField.field_fk
            )?.value ?? 0;

          const updateAlreadyAddedToTotal = () =>
            setAlreadyAddedToTotal([
              ...alreadyAddedToTotal.filter(
                (field) => field.field_fk !== quotationField.field_fk
              ),
              quotationField,
            ]);

          if (amountToBeDeducted) {
            setTotalValue(
              totalValue -
                Number(amountToBeDeducted) +
                Number(quotationField.value ?? 0)
            );
            updateAlreadyAddedToTotal();
          } else {
            setTotalValue(totalValue + Number(quotationField.value ?? 0));
            updateAlreadyAddedToTotal();
          }
        };

        return (
          <span>
            €
            <InputNumber
              style={{ marginLeft: "8px" }}
              min={0}
              defaultValue={findQuotationField(field.id).value || 0}
              precision={2}
              decimalSeparator=","
              onChange={(e) => {
                const value = DOMPurify.sanitize(e);
                if (!Number(value) && value.length > 0) {
                  return;
                }

                setQuotationFields((old) => {
                  const oldField = findQuotationField(field.id);
                  old[old.indexOf(oldField)] = {
                    ...oldField,
                    value: DOMPurify.sanitize(e) || 0,
                  };
                  return old;
                });

                handleTotalValue();
              }}
              step={0.01}
            />
          </span>
        );
      }
      case "LIST":
        const defaultField = field.fieldValues.find((el) => {
          return el.value === findQuotationField(field.id).value;
        });
        return (
          <Select
            defaultValue={
              defaultField ? defaultField.value : field.fieldValues[0].value
            }
            onChange={(e) => {
              setQuotationFields((old) => {
                const oldField = findQuotationField(field.id);
                old[old.indexOf(oldField)] = { ...oldField, value: e };
                return old;
              });
            }}
          >
            {field.fieldValues.map((item, index) => {
              return (
                <Option key={index} value={item.value}>
                  {getLabel(
                    item.value.toLowerCase().replace(/\./g, ""),
                    true,
                    strings
                  )}
                </Option>
              );
            })}
          </Select>
        );
      default:
        return null;
    }
  };

  if (loading) {
    content = (
      <Row type="flex" justify="center" gutter={16}>
        <Col style={{ marginTop: "50%" }} span={1}>
          <Spin size="large"></Spin>
        </Col>
      </Row>
    );
  }

  if (error) {
    content = (
      <Row type="flex" justify="center" gutter={16}>
        <Col style={{ marginTop: "128px" }} span={1}>
          <h1>Er ging iets mis</h1>
        </Col>
      </Row>
    );
  }

  if (!loading && !mutationLoading && data && fields && quotationFields) {
    let sortedBikeTypes = [];
    data.bikeTypes.forEach((el, index) => {
      switch (el.code) {
        case "MECHANIC_BIKE":
          sortedBikeTypes[0] = el;
          break;
        case "ELEC_BIKE":
          sortedBikeTypes[1] = el;
          break;
        case "SPEED_PEDELEC":
          sortedBikeTypes[2] = el;
          break;
        case "E-STEP":
          sortedBikeTypes[3] = el;
          break;
        default:
          console.error("Unrecognized bike type...");
          return;
      }
    });
    content = (
      <Form>
        <Row type="flex" justify="center" title="Nieuwe offerte" gutter={8}>
          <Col span={12}>
            {userDetails.superUser && currentDealer.id === -1 && (
              <Form.Item label="Dealer">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={quotation.dealer_fk}
                  onChange={(val) => {
                    setQuotation((old) => {
                      return { ...old, dealer_fk: val };
                    });
                  }}
                >
                  {userDealers.map((el, index) => {
                    return (
                      <Option value={el.id} key={index}>
                        {el.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label={getLabel("deliverability", true, strings)}
              required={true}
            >
              <Input
                defaultValue={quotation.deliverability}
                placeholder={
                  getLabel("Stock winkel, Stock leverancier", true, strings) +
                  ", ..."
                }
                onChange={(e) => {
                  const val = DOMPurify.sanitize(e.target.value);
                  setQuotation((old) => {
                    return { ...old, deliverability: val };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label={getLabel("bike type", true, strings)}
              required={true}
            >
              <Select
                defaultValue={
                  quotation.bike_type_fk != null
                    ? sortedBikeTypes.find((el) => {
                        return el.id === quotation.bike_type_fk;
                      }).id
                    : null
                }
                onChange={(val) => {
                  setQuotation((old) => {
                    return { ...old, bike_type_fk: val };
                  });
                }}
              >
                {sortedBikeTypes.map((item, index) => {
                  return (
                    <Option key={index} value={item.id}>
                      {getLabel(item.name, true, strings)}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={getLabel("bike brand", true, strings)}
              required={true}
            >
              <Select
                defaultValue={
                  quotation.bike_brand_fk ? quotation.bike_brand_fk : null
                }
                showSearch
                optionFilterProp="children"
                onChange={(val) => {
                  setQuotation((old) => {
                    return { ...old, bike_brand_fk: val };
                  });
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {data.bikeBrands.map((item, index) => {
                  return (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label={getLabel("Totaal offerte", true, strings)}>
              <span>
                €
                <InputNumber
                  style={{ marginLeft: "8px", color: "rgb(0, 0, 0, 0.65)" }}
                  min={0}
                  value={totalValue}
                  precision={2}
                  decimalSeparator=","
                  step={0.01}
                  disabled
                />
              </span>
            </Form.Item>

            {!hasParentCompany && data.company.calculation_sheet && (
              <Form.Item label={getLabel("Calculation Sheet", true, strings)}>
                {calculationSheetId ? (
                  <Text type="secondary">{"Calculation sheet is ready"}</Text>
                ) : (
                  <Text type="secondary">
                    {"Creating calculation sheet, please wait..."}
                  </Text>
                )}
              </Form.Item>
            )}

            {!hasParentCompany && !data.company.calculation_sheet && (
              <Text type="secondary">
                This company doesn't have a parent company or a calculation
                sheet
              </Text>
            )}
          </Col>
          <Col span={12}>
            {fields.map((item, key) => {
              return (
                <Form.Item
                  key={key}
                  label={getLabel(
                    item.fieldType.name.toLowerCase().replace(/\./g, ""),
                    true,
                    strings
                  )}
                >
                  {item.description && (
                    <Text
                      style={{
                        display: "block",
                        fontSize: "8pt",
                        lineHeight: "10pt",
                      }}
                      type="secondary"
                    >
                      {getLabel(
                        item.description.replace(/\./g, ""),
                        true,
                        strings
                      )}
                    </Text>
                  )}
                  {mapField(item)}
                </Form.Item>
              );
            })}
          </Col>
        </Row>
        <Row type="flex" justify="end" gutter={8}>
          <Col span={4}>
            <Button disabled={mutationLoading} onClick={props.onClose}>
              {getLabel("cancel", true, strings)}
            </Button>
          </Col>
          <Col span={4}>
            <Button
              disabled={mutationLoading || !canSave()}
              type="primary"
              onClick={onSave}
            >
              {getLabel("save", true, strings)}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  } else {
    content = (
      <Row type="flex" justify="center" style={{ marginTop: "64px" }}>
        <Spin size="large" />
      </Row>
    );
  }

  return content;
};

export default QuotationNew;
